import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start()
import 'stylesheets/sandwellsendiass/application.scss'
import 'javascripts/navbar'
import 'javascripts/cms_pages'
import 'javascripts/blog_article_browser'
import 'javascripts/glide'
import 'javascripts/site_searches'
import 'javascripts/was_this_useful'
import 'javascripts/form_validation'
